import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galeria</h2>
          <p>
          Deslize pelas fotos para descobrir a tecnologia de ponta que está por trás das nossas máquinas, 
          a habilidade artesanal que as monta e os resultados incríveis que elas produzem. 
          Se você está em busca de qualidade superior e desempenho incomparável, você está no lugar certo.

          Fique à vontade para explorar nossa galeria de fotos e conhecer mais sobre nossa história, 
          nossos produtos e a paixão que impulsiona tudo o que fazemos. 
          Se você tiver alguma pergunta ou estiver interessado em nossas máquinas perfiladeiras, 
          entre em contato conosco. Estamos aqui para atendê-lo e fornecer soluções sob medida para suas necessidades.


          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Carregando..."}
          </div>
        </div>
      </div>
    </div>
  );
};
