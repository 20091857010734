import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Conheça nosso Time</h2>
          <p>
          Nossa equipe é formada por engenheiros talentosos, técnicos experientes e profissionais comprometidos com a qualidade. 
          Cada membro desempenha um papel fundamental no processo de criação das nossas máquinas perfiladeiras de última geração. 
          Com anos de experiência no setor, eles são verdadeiros especialistas no que fazem.
          Queremos que você conheça as pessoas por trás do nosso sucesso. Explore as histórias individuais, 
          a paixão e o comprometimento de cada membro da equipe. Eles são mais do que apenas profissionais - são os pilares da nossa inovação e qualidade.

          Acreditamos que, ao conhecer a equipe que está trabalhando para atender às suas necessidades, 
          você terá uma compreensão mais profunda do nosso compromisso com a excelência. 
          Estamos ansiosos para mostrar a você o rosto humano por trás das nossas máquinas de alta tecnologia.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "Carregando"}
        </div>
      </div>
    </div>
  );
};
